

li{
    padding: 12px;
    border-bottom: 1px solid #dde1e5;
}
.list_active{
    color: #008060;
    border-left: 5px solid #008060;
    border-radius: 3px;
   
}
.image{
    width: 50px;
}
.list_active img{
    filter: invert(100%) sepia(0%) saturate(7500%) hue-rotate(240deg) brightness(100%)
   
}