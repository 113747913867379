
  .heading5 {
    border-radius: 10%;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  .para {
    text-align: center;
    font-size: 1.5rem;
    font-weight: 700;
    margin-bottom: 10%;
  }
  .btn22 {
    background-color: #008060;
    color: white;
    width: 90%;
    border-radius: 56px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    margin: 2rem 1rem;
    padding: 7px;
  }
  .btn22_actived {
    background-color: #D3D3D3;
    color: white;
    width: 90%;
    border-radius: 56px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    margin: 2rem 1rem;
    padding: 7px;
  }
  .box1 {
    height: 60%;
    width: 25%;
    background-color: #fff;
    border-radius: 8%;
  }
  .main2{
      position: absolute;
      top: 110%;
      text-align: center;
      /* width: 100%; */
      color: #797878;
  }
 
  @media (max-width:768px) {
     .main2{
      top: 100%;
     }    
     .box1{
      width: 28%;
     }
  }
  @media (max-width:425px) {
      .box{
          flex-direction: column;
      }
      .box1{
          width: 70%;
      }
      .main2{
          top: 230%;
      }
  }
 