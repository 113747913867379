body{
  font-size: 13px !important;
  letter-spacing: normal;
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "San Francisco", "Segoe UI", Roboto, "Helvetica Neue", sans-serif !important;
 }
 .button_bg_css{
  background: #008060 !important;
}
.text_manage_css{
  margin-left: 10px;

}