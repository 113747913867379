.wrap {
  /* / border: 2px solid red;  / */

  padding: 1%;
}

.wrap3 {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1% 0%;
  gap: 5%;
}

.div1,
.div2,
.div3 {
  width: 100%;
  height: 15%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: white;
  border: none;
  padding: 10px;
  box-shadow: 0 .5rem 1rem #00000026 !important;
  box-shadow: var(--bs-box-shadow) !important;
}

.card_content {
  font-weight: bold;
  font-size: 24px;
  color: green
}

.display_css {
  display: none;
}

.custom_css { 
  padding: 20px;
  font-size: 13px;
  font-family: cursive;
}
.div11 { 
  /* justify-content: center; */
  /* align-items: center; */
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "San Francisco", "Segoe UI", Roboto, "Helvetica Neue", sans-serif !important;
  flex-direction: column;
  background-color: white;
  border: none;
  padding: 10px;
  box-shadow: 0 .5rem 1rem #00000026 !important;
  box-shadow: var(--bs-box-shadow) !important;
}