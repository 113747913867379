.btnSubmit{
  background-color: #000;
  color: #fff;
  padding: 1%;
  border: none;
  cursor: pointer;
}
.btnSubmit:hover{
  background-color: #000;
  color: #fff;
}
.login{
  margin : auto;
  width: 50%;
  transform: translate(0%, 50%);
  background-color: #f3f3f3;
  border-radius:30px ;
}
.containerBox{
  padding: 7% 5%;
}
.form-group {
  margin-bottom: 3%;
}
.form-group input{
  height: 1.5rem;
}
.logo_design{
  width: 229px;
  border-right: 2px solid #dee2e6;
}
.notification{
  background: #F5F7FA;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    align-items: center;
    display: flex;
    justify-content: center;
    cursor: pointer;
}
.profile{
  width: 35px;
  height: 35px;
  border-radius: 50%;
  cursor: pointer;
}
.button_bg_css{
  background: #008060 !important;
}
.plan_name_css{
  font-weight: 700; 
  color: #0d6efc;
  cursor: pointer;
}
