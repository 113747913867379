.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked+.slider {
  background-color: #008060;
}

input:focus+.slider {
  box-shadow: 0 0 1px #008060;
}

input:checked+.slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
  height: 27px;
  width: 54px;
}

.slider.round:before {
  border-radius: 50%;
  height: 20px;
  width: 20px;
}

.publish {
  background: #DEECF6;
  width: fit-content;
}

.review_title {
  margin-right: 1.5rem !important;
}
.font{
  font-size: 18px;
}
.button_bg_css{
  background: #008060 !important;
  color: white !important;
}
.for_icone i{
  /* color: #008060; */
  padding: 5px;
}