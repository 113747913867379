* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
p{
  margin-bottom: 0px !important;
}
.review_nav {
  padding: 10px 10px;
  background-color: #dae4ee;
  border-radius: 10px;
}

.review-list th {
  border-bottom: 1px solid gray;
  padding: 15px;
}

.nps_name{
  width: 10% !important;
}
.product_Availability{
  width: 18% !important;
}
.discountandoffer{
  width: 20% !important;
}
.star_rate{
  width: 11%;
}

.color {
  fill: gold;
}
.table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

td,
th {
  text-align: left;
  padding: 8px;
}

tr:nth-child(even) {
  background-color: #dddddd;
}

.review-full {
  margin-bottom: 0px;
}
.review-list td.actions svg {
  fill: red;
  font-size: 22px;
}
.review-list td.actions a svg {
  fill: #0e6efd;
  font-size: 22px;
  margin-right: 10px;
  margin-left: 10px;
}

.selected{
  color: #FFD700;
}
.emptystar{

  color: #d1bebe;
}
.text_align{
  display: flex;
  justify-content: center;
}