* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.review_nav {
  padding: 10px 10px;
  background-color: #deecf6 !important;
  border-radius: 10px;
  margin: 0px !important;
}
/*
.review_nav {
    padding: 5px 15px;
}
.review_nav:hover{
    background-color: white;
    border-radius: 10px;
    padding: 5px 15px;
    cursor: pointer;
}
.drop_select {
    width: fit-content !important;
} */
.review-list th {
  border: none;
  border-bottom: 1px solid gray;
  padding: 15px;
}
.product {
  width: 20%;
}
.rating {
  width: 12%;
}
.heading{
  width: fit-content
}
.sku{
  width: 10%;
}
.content {
  width: 20%;
}
.status {
  width: 15%;
}
.actions {
  width: 10%;
}

.prod_image {
  width: 15%;
  aspect-ratio: 3/2;
  object-fit: contain;
  mix-blend-mode: multiply;
}

.user_name {
  font-size: 10px;
  /* left: 10px; */
  position: relative;
  margin-top: 5px;
}
/* .color {
  fill: gold;
} */
.table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

td,
th {
  text-align: left;
  padding: 8px;
}

tr:nth-child(even) {
  background-color: #dddddd;
}

.review-full {
  margin-bottom: 0px;
}
.review-list td.actions svg {
  fill: red;
  font-size: 22px;
}
.review-list td.actions a svg {
  fill: #0e6efd;
  font-size: 22px;
  margin-right: 10px;
  margin-left: 10px;
}
.pagination.justify-content-center {
  margin-top: 15px;
  margin-bottom: 15px;
}
/* td.status select{
  color:green;
}
td.status.dis select{
  color:red;
} */
.selected{
  color: #FFD700;
}
.emptystar{

  color: #d1bebe;
} 

.vertical-buttons button {
  display: block; /* Display buttons as block elements */
  width: 100%; /* Set width to fill container */
  margin-bottom: 10px; /* Optional: Add margin between buttons */
}
