.help {
    height: 600px;
    width: 100%;
}

.help-box {
    padding: 15px;
    background-color: #F7F7F7;
    border-radius: 10px;
}

.contact-box {
    border-radius: 10px;
    background: white;
}


.editor-box {
    background-color: white;
    padding: 20px;
    border-radius: 10px;
}

.editor-box textarea {
    height: 100px;
    width: 100%;
    border-radius: 5px;
    padding: 10px;
    border: none;
    box-shadow: 0px 0px 2px grey;
}

textarea:hover,
textarea:focus {
    border: 1px solid rgb(33, 177, 249);
    outline: none;
}

.send-btn {
    margin-top: 10px;
}

.plan_btn {
    display: none !important;
}

@media (max-width : 768px) {
    .help-box {
        margin: 5px;
        padding: 5px;
    }

    .contact-box {
        background-color: black;
        color: white;
        margin: 5px 10px;
        padding: 20px;
        border-radius: 10px;
        line-height: 30px;
    }




}