* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  p{
    margin-bottom: 0px !important;
  }
  .review_nav {
    padding: 10px 10px;
    background-color: #dae4ee;
    border-radius: 10px;
  }

  .review-list th {
    border-bottom: 1px solid gray;
    padding: 15px;
  }
  .Customer{
    width: 20%;
  }
  .rating {
    width: 12%;
  }
  .content {
    width: 30%;
  }
  .status {
    width: 15%;
  }
  .actions {
    width: 10%;
  }
  .siteheading{
    width: 13%;
  }
  
  .prod_image {
    width: 15%;
    aspect-ratio: 3/2;
    object-fit: contain;
    mix-blend-mode: multiply;
  }
  
  .user_name {
    font-size: 10px;
    /* left: 10px; */
    position: relative;
    margin-top: 5px;
  }
  .color {
    fill: gold;
  }
  .table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
  }
  
  td,
  th {
    text-align: left;
    padding: 8px;
  }
  
  tr:nth-child(even) {
    background-color: #dddddd;
  }
  
  .review-full {
    margin-bottom: 0px;
  }
  .review-list td.actions svg {
    fill: red;
    font-size: 22px;
    margin-left: 13px;
  }
  .review-list td.actions a svg {
    fill: #0e6efd;
    font-size: 22px;
    margin-right: 10px;
    margin-left: 10px;
  }

  